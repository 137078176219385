<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="服务协议"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <van-empty
      v-else-if="state.error"
      image="error"
      :description="state.error_text"
    />
    <div v-else v-html="state.agreement" class="p-3"></div>
  </div>
</template>

<script>
import { Empty, Toast, Loading } from "vant";
import { getAgreement } from "@/api/option.service";
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {
    [Empty.name]: Empty,
    [Loading.name]: Loading,
  },
  setup() {
    const state = reactive({
      loading: true,
      error: false,
      error_text: "",
      agreement: "<h1>22</h1>",
    });
    const loadAgreement = async () => {
      try {
        const { result } = await getAgreement();
        state.agreement = result.agreement;
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
        state.loading = false;
        state.error = true;
        state.error_text = msg;
      }
    };
    onMounted(() => {
      loadAgreement();
    });
    return { state };
  },
};
</script>
