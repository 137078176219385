import ApiService from "./api.service";

export function getAgreement() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/option/getAgreement")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getServiceHotline() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/option/getServiceHotline")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
